import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Grid";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import TransactionDetailsDialog from "./TransactionDetailsDialog";
import { stringAvatar, toCurrency } from "utils/helpers";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  styled,
  Typography,
  Paper,
} from "@mui/material";
import axios from "axios";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "ChargeDate",
    label: "Date Ordered",
  },
  {
    id: "CheckNumber",
    label: "Check Number",
    align: "center",
  },
  {
    id: "Total",
    label: "Payment Amount",
    align: "center",
  },
  {
    id: "RunningBalance",
    label: "Running Balance",
    align: "center",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell?.align || "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "desc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const TransactionsTable = ({ transactions }) => {
  const [open, setOpen] = useState(false);
  const [saleData, setSaleData] = useState([]);
  const [selectedSaleId, setSelectedSaleId] = useState(null);
  const [selectedChargeDate, setSelectedChargeDate] = useState(null);
  const [selectedCheckNumber, setSelectedCheckNumber] = useState(null);
  const [selectedOrderItems, setSelectedOrderItems] = useState(null);
  const [selectedSubtotal, setSelectedSubTotal] = useState(null);
  const [selectedTip, setSelectedTip] = useState(null);
  const [selectedTax, setSelectedTax] = useState(null);
  const [selectedServiceFee, setSelectedServiceFee] = useState(null);
  const [selectedDiscounts, setSelectedDiscounts] = useState(null);
  const [selectedTotal, setSelectedTotal] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (
    SaleId,
    CheckNumber,
    ChargeDate,
    OrderItems,
    SubTotal,
    Tip,
    Tax,
    ServiceFee,
    Discounts,
    Amount
  ) => {
    // console.log(selectedOrderItems);
    setSelectedSaleId(SaleId);
    setSelectedCheckNumber(CheckNumber);
    setSelectedChargeDate(ChargeDate);
    setSelectedOrderItems(OrderItems);
    setSelectedSubTotal(SubTotal);
    setSelectedTip(Tip);
    setSelectedTax(Tax);
    setSelectedServiceFee(ServiceFee);
    setSelectedDiscounts(Discounts);
    setSelectedTotal(Amount);
    setOpen(true);
  };

  const getDataForSaleId = async (selectedSaleId) => {
    try {
      const response = await fetch(
        `https://ecommv2.servingintel.com/customer/sale-items/${serverId}/${siteId}/${selectedSaleId}`
      );
      const data = await response.json();
      console.log(data);
      setSaleData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  useEffect(() => {
    if (selectedSaleId) {
      getDataForSaleId(selectedSaleId);
    }
  }, [selectedSaleId]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("ChargeDate");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rows = useMemo(() => {
    return transactions.map(({ Total, ...others }) => {
      return {
        ...others,
        Total: parseInt(Total / 100),
      };
    });
  }, [transactions]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    const sortedRows = [...rows].sort((a, b) => {
      if (orderBy === "ChargeDate") {
        const dateA = new Date(a.ChargeDate);
        const dateB = new Date(b.ChargeDate);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else if (orderBy === "Amount") {
        return order === "asc" ? a.Amount - b.Amount : b.Amount - a.Amount;
      }
      // Add more sorting conditions for other properties if needed

      return 0; // default return for equal values
    });

    return sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [rows, page, rowsPerPage, order, orderBy]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>TRANSACTION</DialogTitle>
          <DialogContent dividers>
            {/* Check Number and Date */}
            <Box
              mb={2}
              px={2}
              py={1}
              borderRadius={4}
              // display="flex"
              // justifyContent="space-between"
            >
              <Typography
                variant="body1"
                component="p"
                // display="flex"
                // justifyContent="space-between"
              >
                Check Number:{" "}
                {isLoading ? (
                  <Skeleton width={80} />
                ) : (
                  <span>{selectedCheckNumber}</span>
                )}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                // display="flex"
                // justifyContent="space-between"
                // mt={1}
              >
                Date:{" "}
                {isLoading ? (
                  <Skeleton width={150} />
                ) : (
                  <span>
                    {moment(selectedChargeDate).format("MMM. DD, YYYY")}
                  </span>
                )}
              </Typography>
            </Box>

            {/* Sale Data Table */}
            <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "1rem",
                        fontWeight: "800",
                        textAlign: "left",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "1rem",
                        fontWeight: "800",
                        textAlign: "left",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "1rem",
                        fontWeight: "800",
                        textAlign: "right",
                      }}
                    >
                      Unit Price
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "1rem",
                        fontWeight: "800",
                        textAlign: "right",
                      }}
                    >
                      Total Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleData.map(
                    (item) =>
                      item.Quantity !== 0 &&
                      item.ReceiptDescription !== "" && (
                        <TableRow key={item.SaleItemId}>
                          <TableCell
                            style={{
                              fontSize: "0.9rem",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ marginLeft: "1.8rem" }}>
                              {item.Quantity}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "0.9rem", textAlign: "left" }}
                          >
                            {item.ReceiptDescription}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "0.9rem", textAlign: "right" }}
                          >
                            {toCurrency(item.BasePrice / 100)}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "0.9rem", textAlign: "right" }}
                          >
                            {toCurrency((item.BasePrice / 100) * item.Quantity)}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Amounts */}
            <Box mt={2} px={2} py={1} borderRadius={4}>
              <Typography
                variant="body1"
                component="p"
                display="flex"
                justifyContent="space-between"
              >
                Subtotal:{" "}
                {isLoading ? (
                  <Skeleton width={60} />
                ) : (
                  <span>{toCurrency(selectedSubtotal || 0)}</span>
                )}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                display="flex"
                justifyContent="space-between"
              >
                Tip:{" "}
                {isLoading ? (
                  <Skeleton width={60} />
                ) : (
                  <span>{toCurrency(selectedTip || 0)}</span>
                )}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                display="flex"
                justifyContent="space-between"
              >
                Tax:{" "}
                {isLoading ? (
                  <Skeleton width={60} />
                ) : (
                  <span>{toCurrency(selectedTax || 0)}</span>
                )}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                display="flex"
                justifyContent="space-between"
              >
                Service Fee:{" "}
                {isLoading ? (
                  <Skeleton width={60} />
                ) : (
                  <span>{toCurrency(selectedServiceFee || 0)}</span>
                )}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                display="flex"
                justifyContent="space-between"
              >
                Discounts:{" "}
                {isLoading ? (
                  <Skeleton width={60} />
                ) : (
                  <span>{toCurrency(selectedDiscounts || 0)}</span>
                )}
              </Typography>
              <Typography
                variant="h6"
                component="p"
                display="flex"
                justifyContent="space-between"
                mt={2}
              >
                Total:{" "}
                {isLoading ? (
                  <Skeleton width={60} />
                ) : (
                  <strong>{toCurrency(selectedTotal || 0)}</strong>
                )}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table
            sx={{ minWidth: 750, background: "white" }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                // console.log(row);

                return (
                  <StyledTableRow
                    hover
                    // onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleClick(
                        row.SaleId,
                        row.CheckNumber,
                        row.ChargeDate,
                        row.OrderItems,
                        row.SubTotal,
                        row.Tip,
                        row.Tax,
                        row.ServiceFee,
                        row.Discounts,
                        row.Amount
                      )
                    }
                  >
                    <TableCell
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                        },
                        color: "rgb(52 100 213)",
                      }}
                    >
                      {moment(row?.ChargeDate).format("MMM. DD, YYYY")}
                    </TableCell>

                    <TableCell align="center">
                      {row?.CheckNumber || 0}
                    </TableCell>

                    <TableCell align="center">
                      {toCurrency(row?.Amount || 0)}
                    </TableCell>

                    <TableCell align="center">
                      {toCurrency(row?.RunningBalance || 0)}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};
export default TransactionsTable;
