import React, { useState, useEffect } from "react";
import FoodCard from "../components/FoodCard";
import {
  Grid,
  Button,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import {
  Alert as BootstrapAlert,
  Modal,
  Button as Button1,
} from "react-bootstrap";
import parseHtml from "html-react-parser";
import { NavBar, LowerNav } from "../navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { scroller } from "react-scroll";
//import { CustomerChat } from "../CustomerChat";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation, Route } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import { setLoading } from "../../../redux/publicReducer/actions";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import { OrderSlider } from "../components/OrderSlider";
import StickyCategoriesNav from "../components/StickyCategoriesNav";
import { capitalize } from "../../../utils/helpers";
import Swal from "sweetalert2";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "wrap",
    height: "auto",
    gridTemplateColumns: "fit-content(300px) fit-content(300px) 1fr",
    transform: "translateZ(0)",
    padding: "0rem 10px",
    backgroundColor: "white",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  dialogTitleBar: {
    margin: 0,
    padding: theme.spacing(2),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const RestaurantMenu = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  const { state } = useLocation();
  const history = useHistory();
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const { search, filterBy, currentRestaurant } = useSelector(
    (state) => state.public
  );

  const [emptyCategory, setEmptyCategory] = useState([]);
  const [updateTime, setUpdateTime] = useState("");
  const { cartItems } = useSelector((state) => state.cart);
  const [chosenCategory, setChosenCategory] = useState("");

  const [show, setShow] = useState(true);
  const buttonClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categories, setCategories] = useState([]);
  const [openWelcome, setOpenWelcome] = React.useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  const handleCloseWelcome = () => {
    setOpenWelcome(false);
  };
  const getSelectedProduct = (id) => {
    setSelectedProduct(id);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    if (state?.category && categories.length) {
      setChosenCategory(state.category.DepartmentID);
      scroller.scrollTo(state.category.DepartmentID, {
        duration: 1500,
        delay: 300,
        smooth: true,
        offset: -110,
      });
    }
  }, [categories]);

  useEffect(() => {
    if (search && search !== "") {
      categories.map((category, i) => {
        const element = document.getElementById(category.DepartmentID);
        if (element) {
          const isImg = document
            .getElementById(category.DepartmentID)
            .getElementsByTagName("img");
          if (isImg) {
            if (isImg.length < 1) {
              document.getElementById(category.DepartmentID).style.display =
                "none";
            } else {
              document.getElementById(category.DepartmentID).style.display =
                "block";
            }
          }
        }
      });
    }
    if (!search || search == "") {
      categories.map((category, i) => {
        document.getElementById(category.DepartmentID).style.display = "";
      });

      scroller.scrollTo(chosenCategory, {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: -110,
      });
    }
  }, [search]);

  // useEffect(() => {
  //   const url =
  //     "https://ecommv2.servingintel.com/customer/checkout-enabled/" +
  //     serverId +
  //     "/" +
  //     siteId;
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       if (response.data.OnOff < 1) {
  //         Swal.fire({
  //           title: "Store Message",
  //           text: response.data.eCommerceDisplayMessage,
  //           icon: "info",
  //           confirmButtonText: "OK",
  //         });
  //         history.push("/");
  //       }
  //     })
  //     .catch((error) => {});
  // }, [updateTime]);

  useEffect(() => {
    dispatch(setLoading(true));
    // const url =
    //   "https://ecommv2.servingintel.com/items/all/" +
    //   siteId +
    //   "?server_id=" +
    //   serverId;

    const url = `https://ecommv2.servingintel.com/items/menu-items/${serverId}/${siteId}`;
    axios
      .get(url)
      .then((response) => {
        if (availableProducts !== response.data) {
          setAvailableProducts(response.data);
        }
      })
      .catch((error) => {});
    dispatch(setLoading(false));
  }, [updateTime]);

  // const interval = setInterval(() => {
  //   axios.get("http://localhost/servingintel_api/public/items/all")
  //   .then((response) => {
  //     if(availableProducts !== response.data)
  //     {
  //       setAvailableProducts(response.data);

  //     }

  //   }).catch(error => {})
  // }, 60000);
  useEffect(() => {
    //const unsub = categoriesRef.onSnapshot((snap) => {

    // const _categories = snap.docs.map((doc) => {
    //   return { ...doc.data(), id: doc.id };
    // });

    //setCategories(_categories);
    //});

    //return unsub;
    const url = `https://ecommv2.servingintel.com/items/menu-categories/${serverId}/${siteId}/${match.params.category.replace(
      /[\-]/g,
      " "
    )}`;
    //const url = "/servingintel_api/public/items/categories";
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {});
  }, [updateTime]);

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // useEffect(() => {
  //   if (getCookie("sic_name")) {
  //     setOpenWelcome(true);
  //   }
  // }, []);

  //temporarily disabled
  // useEffect(() => {
  //   var source2 = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
  //   );
  //   source2.onmessage = function (event) {
  //     var u_items = JSON.parse(event.data);
  //     //console.log(u_items[0].utime);
  //     if (u_items[0].time != "") {
  //       if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
  //         setUpdateTime(u_items[0].time);
  //         sessionStorage.setItem("update_date_time", u_items[0].time);
  //       }
  //     }
  //   };
  // }, []);

  return (
    <React.Fragment>
      <div className="OrderNow">
        {siteId === 89 ? (
          <Modal show={show} onHide={buttonClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                WELCOME TO AJ SLIDERS! asdasdasd
                <br />
                <br />
                WHICH LOCATION WOULD YOU LIKE TO PICK-UP YOUR FOOD?
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <div
                className="d-grid gap-2"
                style={{ width: "100%", height: "100%" }}
              >
                <Button1 variant="danger" size="lg" onClick={buttonClose}>
                  AJ SLIDERS - ST.CHARLES, IL
                </Button1>
                <Button1
                  variant="danger"
                  size="lg"
                  onClick={(event) =>
                    (window.location.href = "https://ajshangout.com/menu")
                  }
                >
                  AJ'S HANGOUT - SOUTH ELGIN, IL
                </Button1>
              </div>
            </Modal.Footer>
          </Modal>
        ) : null}
        <MenuNavigation style={{ position: "sticky", top: "0" }} />
        <OrderSlider />
        <NavBar />
        <div
          class="d-flex justify-content-center flex-wrap"
          style={{ width: "100%", textOverflow: "ellipsis" }}
        >
          {/* {!isMobile ? (
            <GridList className={classes.gridList} spacing={-2}>
              {categories.map((category) => {
                return (
                  <Link
                    activeClass="active"
                    to={category.DepartmentID}
                    spy={true}
                    smooth={true}
                    offset={-110}
                    duration={300}
                    key={category.DepartmentID}
                    onClick={() => {
                      dispatch(setSearch(""));
                      setChosenCategory(category.DepartmentID);
                    }}
                    style={{
                      justifyContent: "center",
                      width: "auto",
                      height: "auto",
                    }}
                  >
                    <MenuItem
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#cc3333",
                        //                        backgroundColor:
                        //                          chosenCategory === category.DepartmentName &&
                        //                          currentRestaurant?.restaurantColor?.hex,
                        color:
                          (chosenCategory === category.DepartmentName && " ") ||
                          "black",
                        textTransform: "none",
                        padding: ".75rem .75rem",
                        marginBottom: ".5rem",
                        marginRight: ".125rem",
                        textAlign: "center",
                        color: "white",
                        fontSize: "1.35rem",
                        justifyContent: "center",
                        //FormControl: 'wrapped',
                        textOverflow: "ellipsis",
                        //whiteSpace: 'break-spaces',
                      }}
                      // fullWidth
                    >
                      {category.DepartmentDescription
                        ? category.DepartmentDescription.toProperCase()
                        : ""}{" "}
                    </MenuItem>
                  </Link>
                );
              })}
            </GridList>
          ) : null} */}
        </div>
        {/* <BlankSpaceTen /> */}
        <div
          className={enableKiosk === "1" ? "eKioskRestaurantNav" : null}
          style={{
            position: "sticky",
            zIndex: 100,
            top: enableKiosk === "0" ? "100px" : "inherit",
          }}
        >
          <StickyCategoriesNav categories={categories} />
        </div>
        <LowerNav />
        <Popover
          className={enableKiosk === "1" ? "KioskMods" : null}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div
            style={{
              minHeight: "80vh",
              maxHeight: "100vh",
              minWidth: isMobile ? "80vw" : "30vw",
            }}
          >
            <List component="nav">
              <ListItem
                button
                onClick={() => {
                  dispatch({ type: "SET_FILTER", payload: "" });
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary="View Full Menu" />
              </ListItem>
              <Divider />
              {categories.map((category) => {
                return (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        dispatch({
                          type: "SET_FILTER",
                          payload: category.DepartmentName,
                        });
                        setAnchorEl(null);
                      }}
                    >
                      <ListItemText primary={category.DepartmentDescription} />
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            </List>
          </div>
        </Popover>
        <Box height=".25rem" />
        {!search && (
          <>
            <Box height=".25rem" />
            {/* <FavoritesSlider /> */}
            {/* <BestSeller /> */}
          </>
        )}

        {/* {categories.map((category, i) => {
          if (search || !isMobile)  {
            return (
              <div
                id={category.DepartmentID}
                style={{
                  margin: isMobile ? "1rem 0rem " : "2.5rem 0rem",
                  padding: "10px 1rem",
                }}
                key={category.DepartmentID}
                onPointerEnter={() => {
                  setChosenCategory(category.DepartmentID);
                }}
              >
                <Typography
                  variant="h4"
                  style={{ marginBottom: "1rem", fontWeight: "bold" }}
                >
                  {category.DepartmentDescription}
                </Typography>

                <Typography
                  variant="subtitle2"
                  style={{
                    marginBottom: "1rem",
                    lineHeight: "19px",
                    fontSize: "1rem",
                  }}
                >
                  {category.WebDescription}
                </Typography>
                {isMobile && <Divider />}
                <Grid container spacing={3}>
                  {availableProducts.map((product) => {
                    const isIncluded =
                      product.Department == category.DepartmentName ? 1 : 0;
                    if (
                      isIncluded &&
                      (!search ||
                        product.ItemDescription.toLowerCase().includes(
                          search?.toLowerCase()
                        ) ||
                        product.ItemName.toLowerCase().includes(
                          search?.toLowerCase()
                        ))
                    ) {
                      return (
                        <Grid
                          xs={12}
                          sm={6}
                          lg={4}
                          spacing={1}
                          key={product.ItemID}
                        >
                          <FoodCard
                            getSelectedProduct={getSelectedProduct.bind(this)}
                            product={product}
                            addRemoveItems={addRemoveItems.bind(this)}
                            //restaurantId={match.params.restaurantId}
                          />
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Grid>
              </div>
            );
          } else {
            return (
              <Accordion className="bg-white" id={category.DepartmentID}>
                <AccordionSummary
                  className="bg-white"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="h4"
                    style={{ marginBottom: "1rem", fontWeight: "bold" }}
                  >
                    {category.DepartmentDescription
                      ? category.DepartmentDescription.toProperCase()
                      : ""}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        marginBottom: "1rem",
                        lineHeight: "19px",
                        fontSize: "1rem",
                      }}
                    >
                      {category.WebDescription}
                    </Typography>
                  </Typography>

                  {isMobile && <Divider />}
                  <Grid container spacing={3}>
                    {availableProducts.map((product) => {
                      const isIncluded =
                        product.Department == category.DepartmentName ? 1 : 0;
                      if (
                        isIncluded &&
                        (!search ||
                          product.ItemDescription.toLowerCase().includes(
                            search?.toLowerCase()
                          ))
                      ) {
                        return (
                          <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            spacing={1}
                            key={product.ItemID}
                          >
                            <FoodCard
                              getSelectedProduct={getSelectedProduct.bind(this)}
                              product={product}
                              addRemoveItems={addRemoveItems.bind(this)}
                              //restaurantId={match.params.restaurantId}
                            />
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          }
        })} */}

        {/* ACCORDION NO ACCORDION  */}
        {categories.map((category, i) => (
          <div
            id={category.DepartmentID}
            style={{
              margin: isMobile ? "1rem 0rem " : "2.5rem 0rem",
              padding: "10px 1rem",
            }}
            key={category.DepartmentID}
            onPointerEnter={() => {
              setChosenCategory(category.DepartmentID);
            }}
          >
            <Stack
              sx={{
                marginLeft: 1,
              }}
            >
              {enableKiosk === "1" ? (
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                  }}
                >
                  {capitalize(category.DepartmentDescription)}
                </Typography>
              ) : (
                <Typography
                  style={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                    fontSize: "1.5rem",
                  }}
                >
                  {capitalize(category.DepartmentDescription)}
                </Typography>
              )}
              <Typography
                variant="caption"
                style={{
                  marginBottom: "1.5rem",
                  fontSize: "1rem",
                  color: "gray",
                }}
              >
                {category.WebDescription}
              </Typography>
            </Stack>
            {isMobile && <Divider />}
            <Grid container spacing={3}>
              {availableProducts.map((product) => {
                const isIncluded =
                  product.Department == category.DepartmentName ? 1 : 0;
                if (
                  isIncluded &&
                  (!search ||
                    product.ItemDescription.toLowerCase().includes(
                      search?.toLowerCase()
                    ) ||
                    product.ItemName.toLowerCase().includes(
                      search?.toLowerCase()
                    ))
                ) {
                  return (
                    <Grid
                      xs={12}
                      sm={6}
                      lg={4}
                      spacing={1}
                      key={product.ItemID}
                      hidden={product.scheduleShow == 0 ? true : false}
                    >
                      <FoodCard
                        getSelectedProduct={getSelectedProduct.bind(this)}
                        product={product}
                        //restaurantId={match.params.restaurantId}
                      />
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </div>
        ))}
        {availableProducts.some(
          (product) =>
            product.Department &&
            product.Department.length === 0 &&
            (!search ||
              product.ItemDescription?.toLowerCase()?.includes(
                search.toLowerCase()
              ))
        ) &&
          !filterBy && (
            <div style={{ margin: "2.5rem 0rem", padding: "1rem" }}>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", marginBottom: "1rem" }}
              >
                Uncategorized
              </Typography>

              <Grid container spacing={3}>
                {availableProducts.map((product) => {
                  if (
                    !product.Department.length &&
                    (!search ||
                      product.ItemDescription.toLowerCase().includes(
                        search.toLowerCase()
                      ))
                  ) {
                    return (
                      <Grid
                        xs={12}
                        sm={6}
                        lg={4}
                        spacing={1}
                        key={product.ItemID}
                        hidden={product.scheduleShow == 0 ? true : false}
                      >
                        <FoodCard
                          getSelectedProduct={getSelectedProduct.bind(this)}
                          product={product}
                          // restaurantId={match.params.restaurantId}
                        />
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </div>
          )}

        <Dialog
          open={openWelcome}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Welcome Back!"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ "text-align": "center" }}
              id="alert-dialog-description"
            >
              <b>{getCookie("sic_firstName")}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ "text-align": "center", display: "block" }}>
            <Button onClick={handleCloseWelcome} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.toolbar} />
      <BottomNavRestaurantMenu />
      <Route exact path="/menu/:category/:ItemDescription">
        <ProductDetailsMenu
          updateTime={updateTime}
          productId={selectedProduct}
        />
        ;
      </Route>
      <Footer />
    </React.Fragment>
  );
};

export const ProductDetailsMenu = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [updateTime, setUpdateTime] = useState(props.updateTime);
  const [defaultChange, setDefaultChange] = useState();
  const [defaultItem, setDefaultItem] = useState();
  const [defaultMod, setDefaultMod] = useState();
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");

  //console.log(match, "matches modal");
  const { cartItems } = useSelector((state) => state.cart);
  const { state } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [productRating, setProductRating] = useState();
  const [restaurantFavorites, setRestaurantFavorites] = useState([]);
  const [required, setRequired] = useState([]);
  const [requiredCheckBox, setRequiredCheckBox] = useState([]);

  useEffect(() => {
    // const unsub = productRef.onSnapshot((snapshot) => {
    //   setProduct({ ...snapshot.data(), id: snapshot.id });
    let current_url = window.location.href;
    current_url = current_url.split("/");
    //console.log(current_url[4]);
    const url = props.productId
      ? "https://ecommv2.servingintel.com/items/item/" +
        siteId +
        "/" +
        props.productId +
        "?server_id=" +
        serverId
      : "https://ecommv2.servingintel.com/items/item_name/" +
        siteId +
        "/" +
        current_url[4].replace("-", " ");

    //const url = "/servingintel_api/public/items/item/";
    axios
      .get(url)
      .then((response) => {
        const m_url =
          "https://ecommv2.servingintel.com/items/mods/" + siteId + "/";
        //const m_url = "/servingintel_api/public/items/mods/";
        response.data.map((item, i) => {
          axios
            .get(m_url + item.ItemID + "?server_id=" + serverId)
            .then((res_mod) => {
              item.mods = res_mod.data;
              setProduct(item);
              item.mods.map((mod) => {
                if (mod.ModifierMinSelect > 0) {
                  setRequired((required) => [...required, mod.ItemID]);
                }
                if (mod.ModifierMinSelect > 1) {
                  setRequiredCheckBox((requiredCheckBox) => [
                    ...requiredCheckBox,
                    mod,
                  ]);
                }
              });
            })
            .catch((error) => {});
        });
      })
      .catch((error) => {});
  }, [updateTime]);

  useEffect(() => {
    setUpdateTime(props.updateTime);
  }, [props.updateTime]);

  // useEffect(() => {
  //   var source3 = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/getItemDetailsUpdate/" +
  //       match.params.productId
  //   );
  //   source3.onmessage = function (event) {
  //     let json = decodeEntities(event.data);
  //     //let d_item = decodeEntities();
  //     console.log(json);
  //     console.log(defaultItem);
  //     //console.log(json.localeCompare(d_item));
  //     if (json.localeCompare(defaultItem) !== 0) {
  //       //setDefaultChange(event.data);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   var source3 = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/getItemModsUpdate/" +
  //       match.params.productId
  //   );
  //   source3.onmessage = function (event) {
  //     if (event.data.localeCompare(defaultMod) !== 0) {
  //       setDefaultChange(event.data);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (cartItems.length) {
      const findProduct = cartItems.find(
        (item) => item.ItemID === match.params?.ItemID
      );
      if (findProduct) {
        setModifiers(findProduct.modifiers);
      }
    }
  }, [cartItems]);

  // useEffect(() => {
  //   const unsub = productRef.collection("ratings").onSnapshot((snapshot) => {
  //     if (snapshot.empty) {
  //       return;
  //     }
  //     let votes = snapshot.docs.length;
  //     let total = 0;
  //     snapshot.docs.forEach((doc) => {
  //       total += doc.data().rating;
  //     });

  //     setProductRating(parseFloat((total / votes).toFixed(1)));
  //   });

  //   return unsub;
  // }, []);

  useEffect(() => {
    const checkIfFavorite = localStorage.getItem(
      `favorites_${match.params.restaurantId}`
    );
    if (checkIfFavorite) {
      const parsedFavorites = JSON.parse(checkIfFavorite);
      setRestaurantFavorites(parsedFavorites);

      const isInFavorite = parsedFavorites.find(
        (favorite) => favorite.id === match.params.productId
      );

      if (isInFavorite) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, []);
  var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }

    return decodeHTMLEntities;
  })();
  return (
    <>
      <Dialog
        open={true}
        onClose={() => history.goBack()}
        fullWidth
        maxWidth={"md"}
        fullScreen={isMobile}
        style={{ borderRadius: "0px" }}
        scroll="paper"
      >
        {product ? (
          <Grid container className="item-container">
            <Grid item style={{ position: "relative" }}>
              <img
                alt={product.ItemDescription}
                height="100%"
                width="100%"
                title={product.ItemDescription}
                loading="lazy"
                src={
                  product.FileName && product.FileName.length > 0
                    ? siteImgSrc + product.FileName
                    : siteMedSrc + "empty-img.webp"
                }
                style={{ width: "100%", borderRadius: "10px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  margin: "1rem 0rem",
                  position: "absolute",
                  right: "0px",
                  top: "300px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <div>
                      <StarIcon
                        style={{ color: "#FFD700", marginRight: "1rem" }}
                        onClick={() => setOpen(true)}
                        fontSize="large"
                      />
                    </div>
                    <div>
                      <Typography variant="h4">{productRating}</Typography>
                    </div> */}
                  <div>
                    {!isFavorite ? (
                      <IconButton
                        onClick={() => {
                          localStorage.setItem(
                            `favorites_${match.params.restaurantId}`,
                            JSON.stringify([product, ...restaurantFavorites])
                          );
                          toast.success("Product Saved to Favorites");
                          setIsFavorite(true);
                          history.goBack();
                        }}
                      >
                        <FavoriteBorderIcon
                          style={{ display: "none", color: "#fff" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          const removedFromFavorites =
                            restaurantFavorites.filter(
                              (fav) => fav.id !== match.params.productId
                            );
                          localStorage.setItem(
                            `favorites_${match.params.restaurantId}`,
                            JSON.stringify(removedFromFavorites)
                          );
                          toast.success("Product Removed From Favorites");
                          setIsFavorite(false);
                          history.goBack();
                        }}
                      >
                        <FavoriteIcon color="secondary" />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              // xs={12}
              // sm={6}
              style={{ position: isMobile ? "" : "relative" }}
            >
              <DialogTitle className={classes.dialogTitleBar}>
                {enableKiosk === "1" ? (
                  <Typography
                    variant="h4"
                    style={{ fontWeight: "800", marginBottom: "1rem" }}
                  >
                    {product.ItemName}
                  </Typography>
                ) : (
                  <Typography
                    component="div"
                    variant="h5"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "600",
                      paddingBottom: "1rem",
                      display: "-webkit-box",
                      "-webkit-line-clamp": "2",
                      overflow: "hidden",
                      "-webkit-box-orient": "vertical",
                      lineHeight: 1.1,
                    }}
                  >
                    {product.ItemName}
                  </Typography>
                )}
                <Typography
                  variant="h5"
                  component="h4"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  $ {numberWithCommas(product.DefaultPrice / 100)}
                </Typography>

                <IconButton
                  onClick={() => history.goBack()}
                  className={classes.close}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ lineHeight: 1.35 }}
                        >
                          {product.ItemDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>

                <div style={{ margin: "1rem 0rem" }}>
                  {product.mods
                    ? product.mods.map((mod, i) => {
                        return (
                          <div>
                            {mod.ModifierMaxSelect == 1 ? (
                              <FormControl fullWidth>
                                <InputLabel>
                                  {mod.ModifierMinSelect > 0
                                    ? parseHtml(
                                        mod.ModifierDescription +
                                          " <span>*</span>"
                                      )
                                    : mod.ModifierDescription}
                                </InputLabel>

                                <Select
                                  id={mod.ItemID}
                                  autoWidth="true"
                                  onChange={(e) => {
                                    // Handle Required
                                    if (mod.ModifierMinSelect > 0) {
                                      if (e.target.value !== "") {
                                        setRequired(
                                          required.filter(
                                            (i) => i !== e.target.value.ParentID
                                          )
                                        );
                                      } else {
                                        setRequired((required) => [
                                          ...required,
                                          e.nativeEvent.target.id,
                                        ]);
                                      }
                                    }
                                    // Add mods
                                    setModifiers(
                                      modifiers.filter(
                                        (i) =>
                                          i.ParentID !== e.target.value.ParentID
                                      )
                                    );

                                    setModifiers((modifiers) => [
                                      ...modifiers,
                                      e.target.value,
                                    ]);
                                  }}
                                >
                                  <MenuItem value="" id={mod.ItemID}>
                                    <em>--Select --</em>
                                  </MenuItem>
                                  {mod.sub_mods
                                    ? mod.sub_mods.map((sub_mod) => {
                                        return (
                                          <MenuItem value={sub_mod}>
                                            {sub_mod.DefaultPrice > 0
                                              ? `${
                                                  sub_mod.ModifierDescription
                                                    ? sub_mod.ModifierDescription
                                                    : sub_mod.ItemDescription
                                                } + $ ${numberWithCommas(
                                                  sub_mod.DefaultPrice / 100
                                                )}`
                                              : `${
                                                  sub_mod.ModifierDescription
                                                    ? sub_mod.ModifierDescription
                                                    : sub_mod.ItemDescription
                                                }`}
                                          </MenuItem>
                                        );
                                      })
                                    : null}
                                </Select>
                              </FormControl>
                            ) : (
                              <p>
                                <FormGroup style={{ "margin-top": "5%" }}>
                                  <InputLabel>
                                    {mod.ModifierMinSelect > 0
                                      ? parseHtml(
                                          mod.ModifierDescription +
                                            " <span>*</span>"
                                        )
                                      : mod.ModifierDescription}
                                  </InputLabel>
                                  {mod.sub_mods
                                    ? mod.sub_mods.map((sub_mod) => {
                                        return (
                                          <FormControlLabel
                                            value=""
                                            control={
                                              <Checkbox
                                                onChange={(e) => {
                                                  const selectedMods =
                                                    modifiers.filter(
                                                      (m) =>
                                                        m.ParentID ==
                                                        sub_mod.ParentID
                                                    );

                                                  //Handle Required
                                                  if (
                                                    mod.ModifierMinSelect > 0
                                                  ) {
                                                    if (e.target.checked) {
                                                      if (
                                                        selectedMods &&
                                                        selectedMods.length ==
                                                          mod.ModifierMinSelect -
                                                            1
                                                      ) {
                                                        setRequired(
                                                          required.filter(
                                                            (i) =>
                                                              i !==
                                                              sub_mod.ParentID
                                                          )
                                                        );
                                                      }
                                                    } else {
                                                      setRequired(
                                                        (required) => [
                                                          ...required,
                                                          sub_mod.ParentID,
                                                        ]
                                                      );
                                                    }
                                                  }
                                                  //Add mods
                                                  if (e.target.checked) {
                                                    if (
                                                      mod.ModifierMaxSelect > 0
                                                    ) {
                                                      if (
                                                        selectedMods &&
                                                        selectedMods.length <=
                                                          mod.ModifierMaxSelect -
                                                            1
                                                      ) {
                                                        setModifiers([
                                                          ...modifiers,
                                                          sub_mod,
                                                        ]);
                                                      }
                                                    } else {
                                                      setModifiers([
                                                        ...modifiers,
                                                        sub_mod,
                                                      ]);
                                                    }
                                                  } else {
                                                    setModifiers(
                                                      modifiers.filter(
                                                        (i) =>
                                                          i.ItemName !==
                                                          sub_mod.ItemName
                                                      )
                                                    );
                                                  }
                                                }}
                                                checked={modifiers.some(
                                                  (m) =>
                                                    m.ItemName ===
                                                    sub_mod.ItemName
                                                )}
                                              />
                                            }
                                            label={
                                              sub_mod.DefaultPrice > 0
                                                ? `${
                                                    sub_mod.ModifierDescription
                                                      ? sub_mod.ModifierDescription
                                                      : sub_mod.ItemDescription
                                                  } + $ ${numberWithCommas(
                                                    sub_mod.DefaultPrice / 100
                                                  )}`
                                                : `${
                                                    sub_mod.ModifierDescription
                                                      ? sub_mod.ModifierDescription
                                                      : sub_mod.ItemDescription
                                                  }`
                                            }
                                          />
                                        );
                                      })
                                    : null}
                                </FormGroup>
                              </p>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>

                {/* <Paper
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: isMobile ? "fixed" : "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: "100%",
                    zIndex: 1000,
                    padding: "1rem",
                    top: "95%",
                  }}
                  elevation={0}
                > */}
                {required.length > 0 ? (
                  <Alert severity="warning">
                    Field(s) with "*" is required.
                  </Alert>
                ) : null}
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div style={{ border: "2px solid black", height: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            if (quantity > 1) {
                              setQuantity(quantity - 1);
                            }
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <div style={{ margin: "0rem 1rem" }}>{quantity}</div>
                        <IconButton onClick={() => setQuantity(quantity + 1)}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      style={{
                        height: "100%",
                        borderRadius: "0px",
                        textTransform: "none",
                        backgroundColor: buttonColor,
                      }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        if (required.length > 0) {
                          document.getElementById(required[0]).scrollIntoView();
                        }
                        if (required.length == 0) {
                          //console.log(cartItems, product, "match");
                          const findProduct = cartItems.find(
                            (item) =>
                              item.ItemID === product.ItemID &&
                              item.mods &&
                              item.mods.every((modifier) =>
                                modifiers.find(
                                  (mod) => mod.ItemName === modifier.ItemName
                                )
                              ) &&
                              (item?.mods?.length === modifiers?.length ||
                                !item.mods ||
                                (!modifiers?.length && !item?.mods?.length))
                          );
                          if (!findProduct) {
                            delete product.mods;
                            dispatch({
                              type: "SET_CART",
                              payload: [
                                ...cartItems,
                                {
                                  ...product,
                                  quantity: quantity,
                                  modifiers: modifiers,
                                  comments: "",
                                  expiration: Date.now() + 86400000,
                                },
                              ],
                            });
                          } else {
                            let newCartItems = cartItems.map((item) => {
                              if (
                                //item.ItemID === findproduct.ItemID &&
                                (item.mods.every((modifier) =>
                                  modifiers.find(
                                    (mod) => mod.ItemName === modifier.ItemName
                                  )
                                ) &&
                                  item.mods.length === modifiers.length) || //item.ItemID === findproduct.ItemID &&
                                (item.mods.length === 0 &&
                                  modifiers.length === 0)
                              ) {
                                delete item.mods;
                                return {
                                  ...item,
                                  quantity: item.quantity + quantity,
                                  comments: "",
                                };
                              }
                              return item;
                            });

                            dispatch({
                              type: "SET_CART",
                              payload: newCartItems,
                            });
                          }
                          // toast.success(
                          //   `Added ${product.ItemDescription} to checkout`,
                          //   { autoClose: 15000 }
                          // );
                          history.goBack();
                        }
                      }}
                    >
                      ADD TO CHECK
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ "margin-top": "5%" }} variant="middle" />
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {product.ItemMessages
                            ? parseHtml(
                                decodeEntities(
                                  product.ItemMessages.replace(
                                    /table/g,
                                    "table style='border: 1px solid #000'"
                                  ).replace(
                                    /td/g,
                                    "td style='border: 1px solid #000'"
                                  )
                                )
                              )
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                {/* </Paper> */}
              </DialogContent>

              <div className={classes.toolbar} />
            </Grid>
          </Grid>
        ) : null}
      </Dialog>
      <ProductRatingMenu open={open} setOpen={setOpen} />
    </>
  );
};

export const ProductRatingMenu = ({ open, setOpen }) => {
  const [rating, setRating] = useState(0);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [sumRating, setSumRating] = useState(0);
  const [usersRated, setUsersRated] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Rate This Product</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(5).fill("star").map((star, index) => {
            return (
              <IconButton onClick={() => setRating(index + 1)}>
                {index + 1 > rating ? (
                  <StarBorderIcon
                    style={{ margin: !isMobile && "1rem" }}
                    fontSize="large"
                  />
                ) : (
                  <StarIcon
                    style={{ margin: !isMobile && "1rem", color: "yellow" }}
                    fontSize="large"
                  />
                )}
              </IconButton>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(setLoading(true));
          }}
        >
          Save Rating
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const BottomNavRestaurantMenu = () => {
  const history = useHistory();
  const { currentRestaurant } = useSelector((state) => state.public);
  const match = useRouteMatch();
  return (
    <Paper
      elevation={10}
      style={{
        position: "fixed",
        bottom: "0px",
        width: "100%",
        display: "none",
      }}
    >
      <BottomNavigation showLabels>
        <BottomNavigationAction
          label="Back"
          icon={<ArrowBackIcon onClick={() => history.goBack()} />}
        />
        <BottomNavigationAction label="Account" icon={<PersonIcon />} />
        <BottomNavigationAction label="Rewards" icon={<MonetizationOnIcon />} />
        <BottomNavigationAction
          label="Favorites"
          icon={
            <FavoriteIcon
              onClick={() =>
                history.push(`/favorites/${match.params.restaurantId}`)
              }
            />
          }
        />
        {/* <BottomNavigationAction
          label="Chat"
          icon={<CustomerChat restaurant={currentRestaurant.restaurantName} />}
        /> */}
      </BottomNavigation>
    </Paper>
  );
};
